import * as React from "react";
import { Global } from "@emotion/react";
import { GlobalStyles as BaseStyles } from "twin.macro";
import { Link } from "gatsby";
import Header from "./sections/Header";
import Footer from "./sections/Footer";
import SEO from "./sections/SEO";
import { customStyles } from "./page.styles";

const NotFoundPage = ({
  t,
  lang,
}: {
  t: Record<string, any>;
  lang: string;
}) => {
  return (
    <>
      <BaseStyles />
      <Global styles={customStyles} />
      <SEO t={t.seo} lang={lang} />
      <Header t={t.header} lang={lang} />
      <main tw="mt-24 py-24 text-center">
        <h1 tw="pb-12 text-4xl">Page not found</h1>
        <Link to="/" tw="underline text-2xl">
          Go home
        </Link>
      </main>
      <Footer t={t.footer} lang={lang} />
    </>
  );
};

export default NotFoundPage;
